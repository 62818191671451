<template>
  <div style="height: calc(100vh - 120px);">
    <listToolbar :toolbar="toolbar" @toolTap="toolTap" @addTap="addTap" />
    <listTable
      :list="list"
      :page="page"
      :loading="loading"
      :total="total"
      :pageSize="pageSize"
      :columns="columns"
      @page-change="pageChange" />
    <el-dialog v-model="formDrawerVisible3" :width="800" title="学生列表">
      <el-table height="540px" border :data="scheduleDetail" v-loading="loading" empty-text="暂无数据" style="width: 100%" @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55" />
        <el-table-column prop="id" label="ID" />
        <el-table-column prop="name" label="姓名" />
        <el-table-column prop="phone" label="手机号" />
        <el-table-column prop="note" label="备注" />
      </el-table>
      <div class="list-but">
        <el-button type="primary" @click="cancel(1)">出勤</el-button>
        <el-button type="primary" @click="cancel(2)">缺课</el-button>
        <el-button type="primary" @click="cancel(3)">消课待补课</el-button>
      </div>
    </el-dialog>
    <el-dialog v-model="formDrawerVisible4" :width="400" title="全员请假">
      <div class="radio-cont">
        <p>类型：</p>
        <label>
          <input type="radio" name="radio" value="4" v-model="radio" />
          <span>学生请假</span>
        </label>
        <label>
          <input type="radio" name="radio" value="5" v-model="radio" />
          <span>教师请假</span>
        </label>
      </div>
      <div class="radio-cont">
        <p>备注：</p>
        <el-input type="textarea" v-model="note" />
      </div>
      <div class="list-but">
        <el-button type="primary" @click="leaveConfirm">确认</el-button>
      </div>
    </el-dialog>
    <el-drawer v-model="formDrawerVisible" size="30%" :title="typeForm === 'add'? '添加' : '修改'">
      <div v-if="formDrawerVisible" style="padding-left: 16px; padding-right: 16px;">
        <Form :columns="addColumns" :type="typeForm" @submit="submit" />
      </div>
    </el-drawer>
  </div>
</template>

<script>
import listToolbar from '@/components/system/listToolbar.vue'
import {
  schoolClassList,
  timetableComplate,
  timetableDel, timetableLeave,
  timetableList,
  timetableStudent, timetableSubmit,
  userList
} from '@/apis/common'
import listTable from '@/components/system/listTable.vue'
import List from '@/components/list.vue'
import Form from '@/components/system/form.vue'

export default {
  name: 'schedule',
  components: { Form, List, listTable, listToolbar },
  data() {
    return {
      loading: false,
      info: JSON.parse(sessionStorage.getItem('user')) || {},
      page: 1,
      pageSize: 15,
      total: 0,
      toolbar: [
        {type: 'selectType', label: '', model: 'type', value: '1', options: [
            {label: '学生课表', value: '1'},
            {label: '教师课表', value: '2'},
            {label: '班级课表', value: '3'},
          ], change: this.changeType},
        {type: 'selectType', label: '', model: 'type_id', value: '', options: [],change: this.changeTypeID},
        {type: 'month', label: '月份', model: 'month', value: '', options: []},
        {type: 'button', label: '搜索', icon: 'el-icon-search', def: 'default', key: 'search'},
        {type: 'button', label: '添加', def: 'primary',key: 'add'}
      ],
      formData: {
        type: '1',
        type_id: '',
        month: '',
        start_date: '',
        end_date: '',
      },
      classList: [],
      studentList: [],
      teacherList: [],
      list: [],
      classColumns: [
        {label: '班级名称', prop: 'class_name', width: 100, type: 'text'},
        {label: '课程名称', prop: 'course_name', width: 100, type: 'text'},
        {label: '课包价格', prop: 'package_price', width: 100, type: 'text'},
        {label: '单次课时', prop: 'single_class_hour', width: 100, type: 'text'},
        {label: '状态', prop: 'status', width: 100, type: 'tag',arr: [
            {key: -2,type: 'success', label: '取消排课'},
            {key: -1,type: 'success', label: '缺课'},
            {key: 0,type: 'success', label: '已预约'},
            {key: 1,type: 'success', label: '手动消课'},
            {key: 4,type: 'success', label: '学生请假'},
            {key: 5,type: 'success', label: '教师请假'},
          ]},
        {label: '授课方式', prop: 'type', width: 100, type: 'tag',arr: [
            {key: 1,type: 'success', label: '线上一对一'},
            {key: 2,type: 'success', label: '线上集体课'},
            {key: 3,type: 'success', label: '线上集体课'},
            {key: 4,type: 'success', label: '线下集体课'},
          ]},
        {label: '上课时间', prop: 'start_time', width: 150, type: 'text'},
        {label: '下课时间', prop: 'end_time', width: 150, type: 'text'},
        {label: '操作', width: 320, type: 'but', arr: [
            {type: 'default', label: '消课', onClick: (row) => this.cancelClasses(row)},
            {type: 'default', label: '全员请假', onClick: (row) => this.leaveTap(row)},
            {type: 'danger', label: '删除', onClick: (row) => this.del(row)}
          ]}
      ],
      studentColumns: [
        {label: '姓名', prop: 'student_name', width: 100, type: 'text'},
        {label: '课程名称', prop: 'course_name', width: 100, type: 'text'},
        {label: '课包价格', prop: 'package_price', width: 100, type: 'text'},
        {label: '单次课时', prop: 'single_class_hour', width: 100, type: 'text'},
        {label: '状态', prop: 'status', width: 100, type: 'tag',arr: [
          {key: -2,type: 'success', label: '取消排课'},
          {key: -1,type: 'success', label: '缺课'},
          {key: 0,type: 'success', label: '已预约'},
          {key: 1,type: 'success', label: '手动消课'},
          {key: 4,type: 'success', label: '学生请假'},
          {key: 5,type: 'success', label: '教师请假'},
        ]},
        {label: '授课方式', prop: 'type', width: 100, type: 'tag',arr: [
          {key: 1,type: 'success', label: '线上一对一'},
          {key: 2,type: 'success', label: '线上集体课'},
          {key: 3,type: 'success', label: '线上集体课'},
          {key: 4,type: 'success', label: '线下集体课'},
        ]},
        {label: '上课时间', prop: 'start_time', width: 150, type: 'text'},
        {label: '下课时间', prop: 'end_time', width: 150, type: 'text'},
        {label: '操作', width: 260, type: 'but', arr: [
            {type: 'default', label: '消课', onClick: (row) => this.cancelClasses(row)},
            {type: 'default', label: '全员请假', onClick: (row) => this.leaveTap(row)},
            {type: 'danger', label: '删除', onClick: (row) => this.del(row)}
          ]}
      ],
      teacherColumns: [
        {label: '姓名', prop: 'teacher_name', width: 100, type: 'text'},
        {label: '课程名称', prop: 'course_name', width: 100, type: 'text'},
        {label: '课包价格', prop: 'package_price', width: 100, type: 'text'},
        {label: '单次课时', prop: 'single_class_hour', width: 100, type: 'text'},
        {label: '状态', prop: 'status', width: 100, type: 'tag',arr: [
            {key: -2,type: 'success', label: '取消排课'},
            {key: -1,type: 'success', label: '缺课'},
            {key: 0,type: 'success', label: '已预约'},
            {key: 1,type: 'success', label: '手动消课'},
            {key: 4,type: 'success', label: '学生请假'},
            {key: 5,type: 'success', label: '教师请假'},
          ]},
        {label: '授课方式', prop: 'type', width: 100, type: 'tag',arr: [
            {key: 1,type: 'success', label: '线上一对一'},
            {key: 2,type: 'success', label: '线上集体课'},
            {key: 3,type: 'success', label: '线上集体课'},
            {key: 4,type: 'success', label: '线下集体课'},
          ]},
        {label: '上课时间', prop: 'start_time', width: 150, type: 'text'},
        {label: '下课时间', prop: 'end_time', width: 150, type: 'text'},
        {label: '操作', width: 320, type: 'but', arr: [
            {type: 'default', label: '消课', onClick: (row) => this.cancelClasses(row)},
            {type: 'default', label: '全员请假', onClick: (row) => this.leaveTap(row)},
            {type: 'danger', label: '删除', onClick: (row) => this.del(row)}
          ]}
      ],
      columns: [],
      formDrawerVisible: false,
      formDrawerVisible3: false,
      formDrawerVisible4: false,
      scheduleDetail: [],
      studHandle: [],
      cancelData: {},
      radio: '4',
      note: '',
      addColumns: [
        {label: '授课方式', prop: 'type', linkage: 'one', main: true, type: 'select',value: '',options: [
            {label: '线上一对一', value: 1},
            {label: '线上集体课', value: 2},
            {label: '线下一对一', value: 3},
            {label: '线下集体课', value: 4},
          ]},
        {label: '学生', prop: 'student_id', linkage: 'one',  linkageValue: '1,3', type: 'student',value: '', options: []},
        {label: '教师', prop: 'teacher_id', type: 'teacher',value: '', options: []},
        {label: '班级', prop: 'class_id', type: 'select', linkage: 'one',  linkageValue: '2,4', value: '', options: []},
        {label: '课程', prop: 'course_id', type: 'course', value: ''},
        {label: '课包', prop: 'course_package_id', type: 'package', value: ''},
        {label: '单次课时', prop: 'single_class_hour', type: 'input', value: ''},
        {label: '授课时间', prop: 'time_str', type: 'select',value: '',options: [
            {label: '不重复', value: -1},
            {label: '每天', value: 0},
            {label: '周一', value: 1},
            {label: '周二', value: 2},
            {label: '周三', value: 3},
            {label: '周四', value: 4},
            {label: '周五', value: 5},
            {label: '周六', value: 6},
            {label: '周日', value: 7},
          ]},
        {label: '开始日期', prop: 'start_date', type: 'date', value: ''},
        {label: '排课数量', prop: 'class_num', type: 'input', value: ''},
        {label: '上课时间', prop: 'start_time', type: 'time', value: ''},
        {label: '下课时间', prop: 'end_time', type: 'time', value: ''},
      ],
      typeForm: 'add',
      addFormData: {}
    }
  },
  mounted() {
    Promise.all([this.getSchoolClassList(), this.getUserList(1), this.getUserList(2)]).then(() => {
      this.toolbar[1].options = this.studentList.map(v => {
        return { label: v.name, value: v.id }
      })
      this.toolbar[1].value = this.studentList[0].id
      this.formData.type_id = this.studentList[0].id
      this.columns = this.studentColumns
      this.getTimetableList()
    })
  },
  methods: {
    submit(e) {
      if(e.type === 1 || e.type === 3) {
        e.class_id = 0
      }else {
        e.student_id = 0
      }
      let form = {
        admin_id: this.info.id,
        school_id: this.info.agency_school_id,
        ...e
      }
      timetableSubmit(form).then(res => {
        this.formDrawerVisible = false
        this.$message.success('操作成功')
        this.getTimetableList()
      })
    },
    addTap() {
      this.addColumns.forEach(item => {
        item.value = ''
      })
      this.formDrawerVisible = true
      this.typeForm = 'add'
    },
    leaveTap(e) {
      this.cancelData = e
      this.formDrawerVisible4 = true
    },
    leaveConfirm() {
      timetableLeave({
        admin_id: this.info.id,
        school_id: this.info.agency_school_id,
        schedule_detail_id: this.cancelData.schedule_detail_id,
        status: this.radio,
        note: this.note
      }).then(r => {
        this.$message.success('操作成功')
      })
    },
    // 删除
    del(row) {
      this.addFormData = row
      this.$confirm('确定要删除吗?', '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        timetableDel({
          admin_id: this.info.id,
          school_id: this.info.agency_school_id,
          schedule_detail_id: row.schedule_detail_id,
        }).then(res => {
          this.$message.success('删除成功')
          this.getTimetableList()
        })
      }).catch(() => {})
    },
    cancel(v) {
      let data = []
      this.studHandle.forEach(x => {
        data.push({
          id: x.id,
          status: v
        })
      })
      timetableComplate({
        admin_id: this.info.id,
        school_id: this.info.agency_school_id,
        schedule_detail_id: this.cancelData.schedule_detail_id,
        json_data: JSON.stringify(data)
      }).then(r => {
        this.$message.success('操作成功')
      })
    },
    handleSelectionChange(e) {
      this.studHandle = e
    },
    cancelClasses(e) {
      this.cancelData = e
      this.formDrawerVisible3 = true
      timetableStudent({
        admin_id: this.info.id,
        school_id: this.info.agency_school_id,
        schedule_detail_id: e.schedule_detail_id
      }).then(r => {
        this.scheduleDetail = r.data.data
      })
    },
    toolTap(data) {
      this.formData = data
      this.page = 1
      this.getTimetableList()
    },
    // 分页
    pageChange(e) {
      this.page = e
      this.getTimetableList()
    },
    changeTypeID(e) {
      this.page = 1
      this.formData.type_id = e
      this.getTimetableList()
    },
    changeType(e) {
      this.page = 1
      let data = []
      if(e === '1') {
        data = this.studentList
        this.columns = this.studentColumns
      }
      if(e === '2') {
        data = this.teacherList
        this.columns = this.teacherColumns
      }
      if(e === '3') {
        data = this.classList
        this.columns = this.classColumns
      }
      this.toolbar[1].options = data.map(v => {
        return { label: v.name, value: v.id }
      })
      this.formData.type = e
      this.toolbar[1].value = data[0].id
      this.formData.type_id = data[0].id
      this.getTimetableList()
    },
    getTimetableList() {
      this.loading = true
      timetableList({
        page: this.page,
        pagesize: this.pageSize,
        admin_id: this.info.id,
        school_id: this.info.agency_school_id,
        ...this.formData
      }).then(res => {
        this.total = res.data.data.count
        this.list = res.data.data.data
        this.loading = false
      })
    },
    getSchoolClassList() {
      return new Promise((rl,ri) => {
        schoolClassList({
          admin_id: this.info.id,
          school_id: this.info.agency_school_id,
          page: 1,
          pagesize: 40,
          name: '',
          is_all: 1
        }).then(res => {
          this.classList = res.data.data.data.map(x => {
            x.label = x.name
            x.value = x.id
            return x
          })
          this.addColumns[3].options = this.classList
          rl()
        })
      })
    },
    getUserList(v) {
      return new Promise((rl,ri) => {
        userList({
          page: this.page,
          pagesize: this.pageSize,
          admin_id: this.info.id,
          school_id: this.info.agency_school_id,
          type: v,
          is_all: 1,
          name: '',
          phone: ''
        }).then(res => {
          if(v === 1) this.studentList = res.data.data.list
          if(v === 2) this.teacherList = res.data.data.list
          rl()
        })
      })
    }
  }
}
</script>

<style scoped>
.list-but{
    text-align: center;
    padding-top: 20px;
}
.radio-cont > p{
    padding: 10px 0;
}
</style>
